header {
  display: flex;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.header__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.header__logo {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.header__logo-first-line {
  line-height: 2.5rem;
  font-weight: bold;
}

.header__logo-second-line {
  line-height: 2.5rem;
  width: fit-content;
  position: relative;
}

.header__logo-second-line:before {
  content: "";
  position: absolute;
  right: 100%;
  top: 0.3rem;
  height: 1.8rem;
  width: 10rem;
  background-color: var(--color-primary);
}

.cta {
  position: absolute;
  top: 1rem;
  width: 100%;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.header__socials {
  position: absolute;
  left: 2rem;
  top: 2rem;
  cursor: pointer;
  z-index: 10;
}

@media screen and (max-width: 600px) {
  .header__socials {
    left: 1rem;
    z-index: 10;
  }
  .header__socials span {
    display: none;
  }
}
